import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from "./Components/HomePage"
import FoundationCourse from './Components/Courses/FoundationCourse';
import MediateCourse from './Components/Courses/MediateCourse'
import Ielts from './Components/Courses/IeltsCourse'
import InterviewCourse from './Components/Courses/InterviewCourse'
import PhonicsCourse from './Components/Courses/PhonicsCourse'
import SeedCourse from './Components/Courses/SeedCourse'
import SpeakOut from './Components/Courses/SpeakOutCourse'
import AboutUs from './Components/AboutUs';
import Career from './Components/Career';
import ContactUs from './Components/ContactUs';
import LanguageTamil from './Components/Languages/Tamil';
import LanguageMalayalam from './Components/Languages/Malayalam';
import LanguageTelugu from './Components/Languages/Telugu';
import BeginnerCourse from './Components/Courses/BeginnerCourse';
import TermsAndConditions from './Components/TermsAndConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import RefundPolicy from './Components/RefundPolicy';
import TamilLandingpage from './Components/TamilLandingpage';
import { Helmet } from 'react-helmet';





function App() {
  return (
    <div >
      <Helmet>
        <title>Learn English Online at Anglofone | Expert Training for All</title>
        <meta
          name="description"
          content="Description: Join Anglofone's Online English courses. Learn online with the best classes. Improve fluency, grammar, and much more!"
        />
        <meta
          name="keywords"
          content="Online English courses, Learn English online, Best online English classes, Affordable English courses, English language training online, Improve English fluency, Spoken English course, English grammar lessons online		
English speaking practice, Anglofone Online English, Anglofone spoken English courses, Anglofone English classes for beginners, Anglofone Tamil to English learning"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/home" element={<HomePage />}></Route>
        <Route path="/Course/Foundation" element={<FoundationCourse />}></Route>
        <Route path="/Course/Mediate" element={<MediateCourse />}></Route>
        <Route path="/Course/IELTS" element={<Ielts />}></Route>
        <Route path="/Course/Interview" element={<InterviewCourse />}></Route>
        <Route path="/Course/Phonics" element={<PhonicsCourse />}></Route>
        <Route path="/Course/Beginner" element={<BeginnerCourse />}></Route>
        <Route path="/Course/Seed" element={<SeedCourse />}></Route>
        <Route path="/Course/Speak-Out" element={<SpeakOut />}></Route>
        <Route path="/Aboutus" element={<AboutUs />}></Route>
        <Route path="/Career" element={<Career />}></Route>
        <Route path="/ContactUs" element={<ContactUs />}></Route>
        <Route path="/Region/Tamil" element={<LanguageTamil />}></Route>
        <Route path="/Region/Telugu" element={<LanguageTelugu />}></Route>
        <Route path="/Region/Malayalam" element={<LanguageMalayalam />}></Route>
        <Route path="/TermsAndConditions" element={<TermsAndConditions />}></Route>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/RefundPolicy" element={<RefundPolicy />}></Route>
        <Route path="/tamil/ppc1" element={<TamilLandingpage />}></Route>
      </Routes>
    </div>
  );
}

export default App;