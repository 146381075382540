import React, { useState, useEffect, useRef } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "../CSS/HomePage.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaWhatsapp } from "react-icons/fa";
import { motion, useScroll } from "framer-motion";
import mobileGif from "../Assets/Images/Tech/AboutUs/creative-reels-composition-3.gif"
import HomePageCourse from "./HomePageCourse";
import bagIcon from "../Assets/Images/Tech/AboutUs/why-choose-icon.svg"
import whyChooseUs from "../Assets/Images/Tech/AboutUs/Why-choose-anglofone-2.gif"
import faqImg from "../Assets/Images/Tech/AboutUs/FAQ.gif"
import partnerlogo from "../Assets/Images/Tech/Clients/partner-logo.svg"
import clients from "../Assets/Images/AboutUs/what people stay.mp4"
import quote from "../Assets/Images/Tech/Clients/icon-quote.svg"
import sat from "../Assets/Images/Tech/Clients/sat-1.svg"
import us from "../Assets/Images/Tech/AboutUs/Why-choose-anglofone-2.gif"
import { Carousel } from 'react-bootstrap';
import banner from "../Assets/Images/Banner/HomeBanner.mp4"
import medal from "../Assets/Images/AboutUs/lecture.svg"
import banner2 from "../Assets/Images/Banner/home page banner.mp4"
import banner3 from "../Assets/Images/Banner/website 1.jpg"
import personalAttention from "../Assets/Images/AboutUs/Complete-personal-attention-2.svg"
import extendedSupport from "../Assets/Images/AboutUs/Practical-learning-with-extended-support.svg"
import trusted from "../Assets/Images/AboutUs/Trusted-by-Millions.svg"
import faqImg2 from "../Assets/Images/Tech/AboutUs/faq-video.mp4"
import us1 from "../Assets/Images/Tech/AboutUs/Why choose anglofone new.mp4"
import why1 from "../Assets/Images/Tech/AboutUs/1.png"
import why2 from "../Assets/Images/Tech/AboutUs/2.png"
import why3 from "../Assets/Images/Tech/AboutUs/3.png"
import why4 from "../Assets/Images/Tech/AboutUs/4.png"
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import whatsappIcon from "../Assets/Images/Tech/Icons/whatsap-white-icon.svg"
import whatsappIcon1 from "../Assets/Images/Tech/Icons/whatsapp.svg"
import sat1 from "../Assets/Images/Tech/Clients/60K studentrs.svg";
import sat2 from "../Assets/Images/Tech/Clients/digital folowers.svg";
import sat3 from "../Assets/Images/Tech/Clients/google review.svg";
import sat4 from "../Assets/Images/Tech/Clients/trainer.svg";
import { Helmet } from "react-helmet";

const HomePage = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [hovered, setHovered] = useState(null);
    // const videoRef = useRef(null);
    const handleLanguageSelect = (languageUrl) => {
        // Directly navigate to the provided URL
        window.location.href = languageUrl;
    };

    const joinUsingWhatsApp = () => {
        console.log("joined using whatsApp")
    }

    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });


    // useEffect(() => {
    //     // Play video immediately on page load
    //     const playVideo = async () => {
    //         try {
    //             if (videoRef.current) {
    //                 videoRef.current.muted = true; // Ensure autoplay compliance
    //                 await videoRef.current.play();
    //             }
    //         } catch (error) {
    //             console.error("Video autoplay failed:", error);
    //         }
    //     };

    //     playVideo();

    //     const handleIntersection = (entries) => {

    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 // Play the video when the section is visible
    //                 videoRef.current.muted = true;
    //                 videoRef.current.play();
    //             } else {
    //                 // Pause and mute the video when the section is not visible
    //                 videoRef.current.muted = true;
    //                 videoRef.current.pause();
    //             }
    //         });
    //     };

    //     const observer = new IntersectionObserver(handleIntersection, {
    //         threshold: 0.5, // Trigger when 50% of the video is in view
    //     });

    //     if (videoRef.current) {
    //         observer.observe(videoRef.current);
    //     }

    //     return () => {
    //         if (videoRef.current) {
    //             observer.unobserve(videoRef.current);
    //         }
    //     };
    // }, []);

    useEffect(() => {
        const slides = ['c1', 'c2', 'c3', 'c4'];
        let currentIndex = 0;

        const interval = setInterval(() => {
            // Uncheck the current slide
            document.getElementById(slides[currentIndex]).checked = false;

            // Move to the next slide
            currentIndex = (currentIndex + 1) % slides.length;

            // Check the next slide
            document.getElementById(slides[currentIndex]).checked = true;
        }, 5000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);


    const gridItems = [
        { img: personalAttention, title: "Complete personal attention", desc: "Complete Personalized Attention.", position: "top-left" },
        { img: extendedSupport, title: "Practical learning with extended support", desc: "Learn with real-life examples in your mother tongue.", position: "top-right" },
        { img: trusted, title: "Trusted by clients", desc: "Learn anytime from anywhere.", position: "bottom-left" },
        { img: trusted, title: "British Council Certified Trainers", desc: "Practice with British council certified trainers.", position: "bottom-right" },
    ];

    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };



    return (
        <div>
            <NavBar navIndex="Home" />
            <Helmet>
                <meta name="description" content="AngloFone is the best online educational firm that teaches people in all walks of life to speak English in a way that eases the learning process." />
            </Helmet>
            <div className="col-md-12" >
                <div className="banner-container">
                    <div className="image-container">
                        <img src={banner3} className="img-fluid d-block mx-auto">
                        </img>

                        <a href="#" class="banner_link" data-bs-toggle="modal" data-bs-target="#customModalinHome">
                            <span class="icon_wrapper">
                                <img class="whatsup" src={whatsappIcon1} alt="WhatsApp Icon" />
                            </span>
                            <span class="text_wrapper">WhatsApp now to join</span>
                        </a>


                        {/* Custom Modal */}
                        <div
                            className="modal fade"
                            id="customModalinHome"
                            tabIndex="-1"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content custom-modal-content">
                                    <div className="modal-header custom-modal-header">
                                        <h5 className="modal-title">Select Language</h5>
                                        <i
                                            className="custom-close-btn"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            &times;
                                        </i>


                                    </div>
                                    <div className="modal-body custom-modal-body">
                                        <ul className="language-list">
                                            <li
                                                onClick={() =>
                                                    handleLanguageSelect("https://wa.link/u8nyi0")
                                                }
                                                className="language-item"
                                            >
                                                Malayalam
                                            </li>
                                            <li
                                                onClick={() =>
                                                    handleLanguageSelect("https://wa.link/0y0yth")
                                                }
                                                className="language-item"
                                            >
                                                Tamil
                                            </li>
                                            <li
                                                onClick={() =>
                                                    handleLanguageSelect("https://wa.link/05xhzb")
                                                }
                                                className="language-item"
                                            >
                                                Telugu
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="animated-text-container">
                        <p className="animated-text">Trained 60K+ students worldwide</p>
                        <p className="animated-text">75+ British Council Certified Trainers</p>
                        <p className="animated-text">4.8 star rating on Google</p>
                        <p className="animated-text">2.5 M+ Digital followers</p>
                    </div>

                </div>
                {/* <img src={howworks} className="img-fluid d-block mx-auto" /> */}



                {/* Overlay text container */}

                <div className="row" style={{ position: "absolute", display: "flex", justifyContent: "space-between", marginLeft: "20px", marginRight: "20px" }}>
                    <div className="col-md-8" style={{ marginTop: "-390px" }} >
                        {/* <h1 className="text-white learnEnglish" >Learn English</h1>
                        <h1 className="anyTime">Any where any Time</h1>
                        <button type="button" className="btn mt-4 p-2 fw-bold" style={{ backgroundColor: "#25D366", color: "white" }} onClick={joinUsingWhatsApp}><FaWhatsapp size={30} /> WhatsApp now to join</button> */}
                    </div>

                    {/* <div style={{ marginTop: "-900px", paddingLeft: "600px", height: "280px" }}>
                        <img src={mobileGif} className="img-fluid" />
                    </div> */}
                </div>

            </div>
            <div>

                {/* <div className="container-fluid ">
                    <div className="container align-center">
                        <div className="row no-flex">
                            <div className="col-12 col-m-6 d-md-flex justify-content-between" style={{ alignItems: "start" }}>
                                <div className="text-start container2">
                                    <h1 className="text-white learnEnglish" >Learn English</h1>
                                    <h1 className="anyTime">Any where any Time</h1>
                                    <button type="button" className="btn mt-4 p-2 fw-bold" style={{ backgroundColor: "#25D366", color: "white" }} onClick={joinUsingWhatsApp}><FaWhatsapp size={30} /> WhatsApp now to join</button>
                                </div>
                                <div className="mb-4 mobileGifContainer">
                                    <motion.img
                                        src={mobileGif}
                                        className="mobileGif"

                                        alt="Animated"
                                        animate={{
                                            y: [0, -20, 0], // Moves up to -20px and back
                                        }}
                                        transition={{
                                            duration: 2, // Animation duration (2 seconds)
                                            repeat: Infinity, // Loop the animation
                                            ease: "easeInOut", // Smoothing function
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}

                <section className="section-testimonials" style={{ backgroundColor: "#fef1f0" }}>
                    <div class="welcome-section">
                        <h1>
                            <span class="title-primary">Welcome to </span>
                            <span class="title-highlight">Anglofone</span>
                        </h1>

                        <p class="description theme-red-text">
                            Anglofone, operating in Tamilnadu, provides inclusive English courses online, acknowledging the evolving landscape of education.
                            The global demand for English proficiency, especially in multilingual countries like India, has driven the popularity and acceptance
                            of online English education.
                        </p>
                    </div>

                    {/* Courses */}
                    <div className="mx-auto">
                        <HomePageCourse />
                    </div>

                    {/* Why choosing us */}



                    <div className="mx-auto">
                        <div className="mx-auto-heading">
                            Why Choose <span className="theme-red-text">Anglofone?</span>
                        </div>
                    </div>



                    <section
                        className="section-why-choose-us d-flex justify-content-center align-items-center"

                    >
                        <div className="container">
                            <div className="row" >
                                {/* First Section - Items */}

                                <div className="col-md-6">
                                    <div className="wrapper_inside">
                                        <div className="container_inside" id="slider">
                                            <input type="radio" name="slide" id="c1" defaultChecked />
                                            <label htmlFor="c1" className="card">
                                                <div className="row">
                                                    <div className="icon"><img src={why1} alt="Learn Anytime" /></div>
                                                    <div className="decs_align">
                                                        <div className="desc">

                                                            <h4>Learn anytime from anywhere</h4>
                                                        </div>
                                                    </div>

                                                </div>
                                            </label>

                                            <input type="radio" name="slide" id="c2" />
                                            <label htmlFor="c2" className="card">
                                                <div className="row">
                                                    <div className="icon"><img src={why2} alt="Personalized Attention" /></div>
                                                    <div className="decs_align">
                                                        <div className="desc">
                                                            <h4>Complete Personalized Attention</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>

                                            <input type="radio" name="slide" id="c3" />
                                            <label htmlFor="c3" className="card">
                                                <div className="row">
                                                    <div className="icon"><img src={why3} alt="Learn Real-Life Examples" /></div>
                                                    <div className="decs_align">
                                                        <div className="desc">
                                                            <h4>Learn with real-life examples in your mother tongue</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>

                                            <input type="radio" name="slide" id="c4" />
                                            <label htmlFor="c4" className="card">
                                                <div className="row">
                                                    <div className="icon"><img src={why4} alt="Practice with Trainers" /></div>
                                                    <div className="decs_align">
                                                        <div className="desc">
                                                            <h4>Practice with British council certified trainers</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Second Section - Simple Hello Div */}
                                <div className="col-md-6 d-flex align-items-center justify-content-center" style={{ backgroundColor: "inherit", overflow: "hidden", height: "550px" }}>
                                    <div>
                                        <video src={us1} className="videoGif" autoPlay loop muted playsInline>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* faq */}
                    <section className="section-faq">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <video src={faqImg2} className="img-fluid d-block mx-auto" autoPlay loop muted playsInline>
                                    </video>
                                </div>
                                <div className="col-md-7">
                                    <div className="row">
                                        {/* <div className="col-md-10">
                                            <div className="spacing"></div>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h4 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            At what time will my English class commence?
                                                        </button>
                                                    </h4>
                                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#ccordionExample">
                                                        <div className="accordion-body">

                                                            At Anglofone, we have flexibly structured our online English courses. You can learn spoken English
                                                            in your leisure time through WhatsApp. Your dedicated trainers will guide you by adapting to your
                                                            schedules.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h4 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Will I able to speak fluently within 2 months?
                                                        </button>
                                                    </h4>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            Learning pace is something that differs from person to person. Our course duration is 2 months. In
                                                            addition to that, your course is valid up to 9 months from which you can become more fluent and
                                                            learn beyond the course even after your course duration.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h4 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            What is the procedure to enroll in the course?
                                                        </button>
                                                    </h4>
                                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            You can text the mentioned contact number on our website. Our course advisor will reach you. There
                                                            will be an online test to evaluate your level in English. After evaluating your test, our advisors
                                                            will suggest the suitable course to you, considering
                                                            your level of English.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h4 className="accordion-header" id="headingFour">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            I'm working abroad. How can I enroll in the course?
                                                        </button>
                                                    </h4>
                                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            You can access our English classes from any part of the world because our trainers are working
                                                            round the clock. We can definitely coordinate with your timings.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h4 className="accordion-header" id="headingFive">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            Will I have a dedicated trainer to guide me?
                                                        </button>
                                                    </h4>
                                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            Yes. You will have 2 personal trainers, 1 calling activity trainer, and 1 live trainer throughout
                                                            the course. Your personal trainers will guide you on grammar-related doubts. Your calling activity
                                                            trainer will guide with your spoken English in telephonic
                                                            conversations. Your live trainer will mentor you with soft skills and teach you to converse
                                                            comfortably in groups.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h4 className="accordion-header" id="headingSix">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            What if I missed my video class sessions on a day?
                                                        </button>
                                                    </h4>
                                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            You can inform your reasons to your trainers, and they will be able to guide you regarding class
                                                            compensation.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {showArrow && (
                        <button
                            className="back-to-top"
                            onClick={scrollToTop}
                            aria-label="Back to top"
                        >
                            ↑
                        </button>
                    )}
                    {/* achievements */}
                    {/* <section className="section-achievements light-red-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6>OUR ACHIEVEMENTS</h6>
                                    <h2>Some of our Achievements
                                        <br />
                                        <span className="theme-red-text">AWARDS</span></h2>
                                </div>


                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-2 col-md-3">
                                            <div className="partner-box">
                                                <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-2 col-md-3">
                                            <div className="partner-box">
                                                <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-2 col-md-3">
                                            <div className="partner-box">
                                                <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-2 col-md-3">
                                            <div className="partner-box">
                                                <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-2 col-md-3">
                                            <div className="partner-box">
                                                <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-2 col-md-3">
                                            <div className="partner-box">
                                                <img src={partnerlogo} className="img-fluid d-block mx-auto" />
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section> */}
                    {/* section Testimonials */}
                    <br></br>
                    <br></br>
                    <section className="ection-testimonials">
                        <div className="container">
                            <div className="testimonial-box">
                                <div className="row">
                                    <div className="col-md-4">
                                        <video src={clients} className="img-fluid d-block mx-auto" autoPlay loop muted playsInline style={{ position: "relative", borderRadius: "30px", overflow: "hidden", }} >
                                        </video>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="ps-6">
                                                    {/* <h6>Testimonials</h6> */}
                                                    <h2>
                                                        Success
                                                        {/* <span className="theme-red-text"> Stories</span> */}
                                                    </h2>
                                                    <img src={quote} className="img-fluid d-block" style={{ height: '20px' }} />
                                                    <Carousel
                                                        controls={false}   // Hide next/prev arrows
                                                        indicators={true}  // Show pagination dots
                                                        interval={3000}    // Auto-slide every 3 seconds
                                                        pause={false}      // Don't pause on hover
                                                    >
                                                        {/* Slide 1 */}
                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Prathisha</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}>
                                                                    The class is very engaging and interactive.
                                                                    It provides a good balance between language inprovement, pronounciation, fluency.
                                                                    The frequent speaking practice through calling activity and video sessions. It helps me in building my language fluency.
                                                                    I am able to speak with confidence now all because of this.
                                                                </p>

                                                            </div>

                                                        </Carousel.Item>

                                                        {/* Slide 2 */}
                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Ranjith</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}> I am fully satisfied learning English with you .
                                                                    Because, I expected what I need that's all are teaching us. For instance, Grammar, Accent, Vocabularies and Spoken activities.
                                                                    Thus, I can improve my knowledge and spoken skill. On top of that all trainers all are doing well great job.
                                                                    I appreciate your job and Thank you so much to all. </p>

                                                            </div>
                                                        </Carousel.Item>

                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Pavithra</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}>
                                                                    Anglofone is a great platform for learning English. The trainers are excellent, and the training methods are very effective. I learn new things day by day, and I can see a difference in my learning. The classes are really good, and the trainers are encouraging and motivating. I can understand grammar very well, and it is so interesting.
                                                                </p>

                                                            </div>

                                                        </Carousel.Item>

                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Sandhiya Ganesh</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}>
                                                                    Anglofone's online classes are very helpful. The trainers are very supportive and always ready to help. After sending in my tasks, they check quickly and give the feedback. They explain any mistakes right away, making it easy to understand. I’m thankful to the trainers for their help. The calling activities are also great for improving.
                                                                </p>

                                                            </div>

                                                        </Carousel.Item>

                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Ayyanar G</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}>

                                                                    I had a fantastic experience with Anglo Fone Spoken English classes! The teachers are not only knowledgeable but also very supportive. My confidence in speaking English has greatly improved thanks to the live sessions and engaging teaching methods. Whether you're a beginner or looking to improve your fluency, this is the perfect place to enhance your English skills. I highly recommend Anglo Fone to anyone looking to excel in spoken English.</p>

                                                            </div>

                                                        </Carousel.Item>

                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Nandhini N</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}>

                                                                    When I joined the Anglofone online Beginner class, I didn't have confidence in speaking English. But now I have develop in my communication skills, improved my vocabulary and include learning how to frame sentences. we had weekly calling activities, which helped me learn a lot. So, thank you to my trainers for their support. Thank you Anglofone for a great learning experience.</p>

                                                            </div>

                                                        </Carousel.Item>

                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Chandri Joe</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}>

                                                                    Anglofone has been instrumental in helping me learn English effectively. The engaging lessons and supportive teachers made the process enjoyable and smooth. I highly recommend it to anyone looking to improve their language skills.</p>

                                                            </div>

                                                        </Carousel.Item>



                                                        <Carousel.Item>
                                                            <div className="align-items-start" >
                                                                <div className="author">
                                                                    <h5>Rohini. P</h5>

                                                                </div>
                                                                <p style={{ color: "#727272" }}> I am very satisfied with this course. The trainer is extremely caring and approachable. I feel confident that by the end of this 3-month course, I'll be able to understand and speak English fluently💯. I’m grateful for this opportunity, and I want to thank the trainer for their dedication and support. 😊♥
                                                                </p>

                                                            </div>

                                                        </Carousel.Item>


                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="satisfied-box">
                            <div className="row">
                                <div className="col-md-5">
                                    {/* <h6>Success Story</h6> */}
                                    <h2 style={{ textAlign: "center",  fontFamily: "impact" }}>Why are we<br/>
                                        <span >the best ?</span>
                                    </h2>

                                    <br></br>
                                    <ul>
                                        <h4 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Friendly and reliable support</h4>
                                        <h4 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Customized learning plans</h4>
                                        <h4 style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>Certified experts</h4>
                                    </ul>


                                </div>
                                <div className="col-md-7 d-flex align-items-center" ref={ref}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                                <div className="image-wrapper">
                                                    <img src={sat4} width={30} className="img-fluid" alt="Trainers" />
                                                </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={75} duration={2} />}+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Trainers</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                                <div className="image-wrapper">
                                                    <img src={sat3} width={30} className="img-fluid" alt="Reviews" />
                                                </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={7} decimals={1} duration={2} />}K+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Google reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                                <div className="image-wrapper">
                                                    <img src={sat1} width={30} className="img-fluid" alt="Students" />
                                                </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={60} duration={2} />}K+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Students</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="satisfyd-box d-flex align-items-center">
                                                <div className="image-wrapper">
                                                    <img src={sat2} width={30} className="img-fluid" alt="Followers" />
                                                </div>
                                                <div>
                                                    <h3>
                                                        {inView && <CountUp start={0} end={2.5} decimals={1} duration={2} />}M+
                                                    </h3>
                                                    <p style={{ fontWeight: "bold" }}>Digital followers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <footer>
                    <Footer />
                </footer>
            </div>
        </div >
    )
}


export default HomePage;