import React, { useEffect, useState } from "react";
// import NavigationBar from "../NavBar";
import "../CSS/TamilLandingpage.css"

import { useLocation } from "react-router-dom";
import advisorImage1 from "../Assets/Images/Tech/Course/why/4.png"
import advisorImage2 from "../Assets/Images/Tech/Course/why/6.png"
import advisorImage from "../Assets/Images/Tech/Course/why/3.png"
import advisorImage3 from "../Assets/Images/Tech/Course/why/2.png"
import arrow2 from "../Assets/Images/Tech/Course/why/compressed/arrow2.png"
import fans from "../Assets/Images/Tech/Course/why/compressed/6 fans.mp4"
import rate from "../Assets/Images/Tech/Course/why/compressed/02 rate.mp4"
import over from "../Assets/Images/Tech/Course/why/compressed/30-overpopulation.mp4"
import icon1 from "../Assets/Images/Tech/Course/icons/1.png"
import icon2 from "../Assets/Images/Tech/Course/icons/2.png"
import icon3 from "../Assets/Images/Tech/Course/icons/3.png"
import icon4 from "../Assets/Images/Tech/Course/icons/4.png"
import icon5 from "../Assets/Images/Tech/Course/icons/5.png"
import icon6 from "../Assets/Images/Tech/Course/icons/6.png"
import icon7 from "../Assets/Images/Tech/Course/icons/7.png"
import icon8 from "../Assets/Images/Tech/Course/icons/8.png"
import gifvid from "../Assets/Images/Tech/Course/hi video Pc.mp4"
import gifvidmob from "../Assets/Images/Tech/Course/hi video phone.mp4"
import whatsappIcon1 from "../Assets/Images/Tech/Icons/whatsapp.svg"
// import Footer from "../Footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow, Pagination } from 'swiper/modules';
import clients from "../Assets/Images/AboutUs/TEXT.jpg"

import { Helmet } from "react-helmet";



const TamilLandingpage = () => {

    const [videoSrc, setVideoSrc] = useState(gifvid);
    const [translateValue, setTranslateValue] = useState(170);


    const icons = [<img src={icon3} alt="Icon 3" key="icon3" className="icon-image" />, <img src={icon2} alt="Icon 2" key="icon2" className="icon-image" />,
    <img src={icon1} alt="Icon 1" key="icon1" className="icon-image" />, <img src={icon8} alt="Icon 8" key="icon8" className="icon-image" />,
    <img src={icon7} alt="Icon 7" key="icon7" className="icon-image" />, <img src={icon6} alt="Icon 6" key="icon6" className="icon-image" />,
    <img src={icon5} alt="Icon 5" key="icon5" className="icon-image" />, <img src={icon4} alt="Icon 4" key="icon4" className="icon-image" />];
    const texts = ["Daily Video classes + Calls – தினமும் practise", "உங்க profession-க்கு தேவைப்படுற English training",
        "Everyday new words training", "Workplace & Daily Life Phrases – Easy English", "Fixed Timing இல்லை",
        "Personal Trainer Support", "British Council Certified Trainers", "2 Month Training + 10 Month Support"];
    const images = [advisorImage1, advisorImage2, advisorImage3, advisorImage];


    const loc = useLocation();
    const handleLanguageSelect = (languageUrl) => {
        // Directly navigate to the provided URL
        window.location.href = languageUrl;
    };




    const [angleOffset, setAngleOffset] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [textOpacity, setTextOpacity] = useState(1);
    const [imageOpacity, setImageOpacity] = useState(1);




    useEffect(() => {
        const interval = setInterval(() => {
            setTimeout(() => {
                setTextOpacity(0);
                setImageOpacity(0);
            }, 1200);


            setTimeout(() => {
                // Step 2: Change text when it's completely faded out (midpoint of animation)
                setActiveIndex((prevIndex) => (prevIndex + 1) % icons.length);
            }, 3900);

            setTimeout(() => {
                // Step 3: Rotate icons smoothly
                setAngleOffset((prevOffset) => prevOffset + 45);
            }, 2000);

            setTimeout(() => {
                setImageOpacity(1);
                setTextOpacity(1);
            }, 4000); // Fade-in begins before rotation stops

        }, 7000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const updateVideoSrc = () => {
            if (window.innerWidth < 768) {
                setVideoSrc(gifvidmob);
            } else {
                setVideoSrc(gifvid);
            }
        };

        updateVideoSrc(); // Call on load
        window.addEventListener("resize", updateVideoSrc);

        return () => window.removeEventListener("resize", updateVideoSrc);
    }, []);


    const [showArrow, setShowArrow] = useState(false);




    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        const updateTranslateValue = () => {
            setTranslateValue(window.innerWidth < 768 ? 75 : 170);
        };

        updateTranslateValue(); // Set value on mount
        window.addEventListener("resize", updateTranslateValue);

        return () => window.removeEventListener("resize", updateTranslateValue);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {

        const { courseName } = loc.state ? loc.state : {};
        console.log("the course is", courseName)

        const script = document.createElement("script");
        script.src = "https://widget.tagembed.com/embed.min.js";
        script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [])


    return (
        <div>
            <Helmet>
                <title>Beginner English Course Online | Start Your Learning Journey</title>
                <meta name="description" content="Description: Start speaking English with Anglofone’s Beginner English lessons. Learn grammar, vocabulary, and everyday communication." />
                <meta name="keywords" content="Beginner English lessons" />
            </Helmet>

            <div>
                <section>


                    <div className="row align-items-center">
                        <div className="english-content col-ld-6 col-xl-6 col-md-12">
                            <div className="gotham-ultra">
                                Speak English
                            </div>
                            <div className="with-confidence">
                                <span className="brittany-text">with</span>
                                <span className="gotham-ultra-1">Confidence</span>
                            </div>
                            <div className="another-line">
                                <span className="simple-easy-text">Personalized Training for Real Results</span>

                            </div>
                            <div className="arrow-container">
                                <img src={arrow2} className="arrow-video" />



                            </div>

                            <div className="postion-of-button">
                                <div className="join-button-now">
                                   
                                    <a
                                        href="https://api.whatsapp.com/send?phone=919952389762&text=Hi%20Nivetha%2C%20I%20would%20like%20to%20know%20more%20about%20the%20course%20details."
                                        className="banner_link_1"

                                    >
                                        <span className="icon_wrapper_1">
                                            <img className="whatsup_1" src={whatsappIcon1} alt="WhatsApp Icon" />
                                        </span>
                                        <span className="text_wrapper_1">CLICK TO JOIN</span>
                                    </a>


                                </div>

                            </div>

                        </div>

                        <div className="col-lg-6 col-md-12 video-content">
                            <video src={videoSrc} className="landing-video" autoPlay loop muted playsInline />
                        </div>
                    </div>


                </section>
                <section>

                    <div className="row align-items-center">
                        <div className="icons-content col-lg-6 col-xl-6 col-md-6 col-sm-12 ">

                            <div className="video-container">
                                <div className="video-item">
                                    <video autoPlay loop muted className="video">
                                        <source src={over} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <p className="video-text">
                                        60,000+ <br />
                                        <span className="lighter-text">students trained</span>
                                    </p>




                                </div>

                                <div className="video-item">
                                    <video autoPlay loop muted className="video">
                                        <source src={fans} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>

                                    <p className="video-text">
                                        3 million+ <br />
                                        <span className="lighter-text">digital followers</span>
                                    </p>
                                </div>

                                <div className="video-item">
                                    <video autoPlay loop muted className="video">
                                        <source src={rate} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <p className="video-text">
                                        7K+ <br />
                                        <span className="lighter-text">Google reviews</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <section className="centered-section">
                    <div className="row align-items-center">
                        <div className="circle-animation col-lg-12 col-md-12 col-sm-12 ">
                            <div className="red-container">
                                <div className="red-text-left" style={{ opacity: textOpacity }}>
                                    {texts[(activeIndex + 6) % texts.length]}
                                </div>
                                <div className="red-text-right" style={{ opacity: textOpacity }}>
                                    {texts[(activeIndex + 2) % texts.length]}
                                </div>
                            </div>
                            <div className="outer-circle">

                                <div className="icons-container">
                                    {icons.map((icon, index) => {
                                        const angleDeg = index * 45 + angleOffset;
                                        return (
                                            <div
                                                key={index}
                                                className="iconinlanding"
                                                style={{
                                                    transform: `rotate(${angleDeg}deg) translate(${translateValue}px) rotate(-${angleDeg}deg)`,
                                                    transition: "transform 5s ease-in-out", // Smooth transition
                                                }}
                                            >
                                                {icon}
                                            </div>
                                        );
                                    })}


                                </div>


                                <div className="separators-container">

                                    {[...Array(8)].map((_, i) => {
                                        const sepAngle = i * 45 + angleOffset;
                                        return (
                                            <div
                                                key={`sep-${i}`}
                                                className="separator"
                                                style={{
                                                    transform: `rotate(${sepAngle + 22.5}deg) translate(${translateValue}px) rotate(90deg)`,
                                                }}
                                            />
                                        );
                                    })}

                                </div>


                                <div className="inner-circle">
                                    <img
                                        src={images[activeIndex % images.length]}
                                        alt="Client"
                                        style={{ opacity: imageOpacity, transition: "opacity 2s ease-in-out" }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {showArrow && (
                    <button
                        className="back-to-top"
                        onClick={scrollToTop}
                        aria-label="Back to top"
                    >
                        ↑
                    </button>
                )}
                <section className="section-why-choose-us section-testimonial-1" >
                    <div className="container what-people">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {/* <h6>Testimonials</h6> */}
                                        <h2>What
                                            <span class="text-primary "> People&nbsp;Say</span>
                                        </h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12" style={{ border: "none" }}>
                                        <h1 className="text-center"> </h1>
                                        <div class="tagembed-widget" style={{ width: "100%", height: "100%" }} data-widget-id="2139187" data-tags="false" view-url="https://widget.tagembed.com/2139187"></div><script src="https://widget.tagembed.com/embed.min.js" type="text/javascript"></script>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <footer>
                <Footer />
            </footer> */}
        </div>

    )
}

export default TamilLandingpage;