import React, { useState, useEffect } from "react";
import NavigationBar from "../NavBar";
import "../../CSS/Courses.css"
import aboutus from "../../Assets/Images/AboutUs/about-img.svg"
import { useLocation } from "react-router-dom";
import rightArrow from "../../Assets/Images/Tech/Icons/right-white-arrow-icon.svg"
import advisorImage from "../../Assets/Images/Tech/Course/why/1.png"
import advisorgif from "../../Assets/Images/Tech/Course/Speakout course.gif"
import faqImg from "../../Assets/Images/Tech/AboutUs/faq-video.mp4"
import Footer from "../Footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import speakout from "../../Assets/Images/Tech/Course/speakout.mp4"
import { Helmet } from "react-helmet";

const SpeakOutCourse = () => {

    const loc = useLocation()
    const handleLanguageSelect = (languageUrl) => {
        // Directly navigate to the provided URL
        window.location.href = languageUrl;
    };

    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };




    useEffect(() => {

        const { courseName } = loc.state ? loc.state : {};
        console.log("the course is", courseName)

        const script = document.createElement("script");
        script.src = "https://widget.tagembed.com/embed.min.js";
        script.async = true;

        // Append to the body or head
        document.body.appendChild(script);

        // Cleanup to avoid script duplication
        return () => {
            document.body.removeChild(script);
        };

    }, [])


    return (
        <div>

            <Helmet>
                <title>Speak Out English Course | Improve Your Communication Skills</title>
                <meta name="description" content="Description: Boost your confidence with Anglofone’s Speak Out English course. One on One speaking practice to become fluent in English." />
                <meta name="keywords" content="Speak Out English course" />
            </Helmet>
            <NavigationBar navIndex="Courses" />

            <div>

                <section className="grow-banner">
                    <div id="carouselExampleIndicators" className="carousel slide pointer-event" data-bs-ride="carousel">
                        <div className="carousel-inner ">
                            <div className="carousel-item about-banner_so active">
                                <div className="container">
                                    <div className="banner align-items-center">
                                        <div className="banner-in ">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {/* <h1>Speak-Out Course</h1> */}
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item">
                                                                {/* <a href="index.html">Home</a> */}
                                                            </li>
                                                            <li className="breadcrumb-item active" aria-current="page">
                                                                {/* <a href="index.html">Speak-Out Course</a> */}
                                                            </li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-why-choose-us light-red-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="pe-3">
                                    <img src={advisorgif} className="img-fluid" />
                                </div>

                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <h6>Course Info</h6> */}
                                        <h2>Learn English
                                            <br></br>
                                            <span className="text-primary">Anywhere ... Anytime...</span>
                                        </h2>
                                        <div className="bullet-arrow">
                                            <ul>


                                                <li>This course is for individuals eager to embark one-on-one English learning journey for both personal and professional growth.

                                                </li>
                                                <li>Participants will engage in interactive video sessions with a personal trainer, attend webinars hosted by language experts, and access free study materials to support their learning.</li>
                                                <li>
                                                    Live sessions and dedicated practice tasks will further enhance communication skills.
                                                </li>

                                            </ul>

                                        </div>
                                        <button
                                            className="btn btn-danger btn-theme join-now-button"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#customModalinHome"
                                        >
                                            Join Now
                                            <img src={rightArrow} alt="grow" className="join-now-arrow" />
                                        </button>


                                        {/* Custom Modal */}
                                        <div
                                            className="modal fade"
                                            id="customModalinHome"
                                            tabIndex="-1"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content custom-modal-content">
                                                    <div className="modal-header custom-modal-header">
                                                        <h5 className="modal-title">Select Language</h5>
                                                        <i
                                                            className="custom-close-btn"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            &times;
                                                        </i>


                                                    </div>
                                                    <div className="modal-body custom-modal-body">
                                                        <ul className="language-list">
                                                            <li
                                                                onClick={() =>
                                                                    handleLanguageSelect("https://wa.link/8wwtk5")
                                                                }
                                                                className="language-item" style={{ listStyle: "none" }}
                                                            >
                                                                Malayalam
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleLanguageSelect("https://wa.link/1w6qy5")
                                                                }
                                                                className="language-item"
                                                            >
                                                                Tamil
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleLanguageSelect("https://wa.link/sml2in")
                                                                }
                                                                className="language-item"
                                                            >
                                                                Telugu
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {showArrow && (
                    <button
                        className="back-to-top"
                        onClick={scrollToTop}
                        aria-label="Back to top"
                    >
                        ↑
                    </button>
                )}
                <section className="section-why-choose-us pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Who Can
                                            <br></br>
                                            <span className="text-primary fw-light">Choose this Course?</span>
                                        </h2>
                                        <section className="swiper-section">
                                            {/* <Swiper
                                                effect={'coverflow'}
                                                grabCursor={true}
                                                centeredSlides={true}
                                                slidesPerView={'auto'}
                                                coverflowEffect={{
                                                    rotate: 0,
                                                    stretch: 0,
                                                    depth: 180,
                                                    modifier: 3,
                                                    slideShadows: true,
                                                }}
                                                loop={true}
                                                
                                                modules={[EffectCoverflow, Pagination]}
                                                className="mySwiper"
                                            >
                                               <SwiperSlide>
                                                    <div className = "testimonialBox">
                                                    <div className="details">
                                                                <div className="imgBx">
                                                                <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                                </div>

                                                            </div>
                                                        <div className ="content">
                                                            <p>Individuals seeking a tailored approach to enhance speaking effectiveness.
                                                            </p>
                                                           
                                                        </div>
                                                    </div>
                                                   
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className = "testimonialBox">
                                                    <div className="details">
                                                                <div className="imgBx">
                                                                <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                                </div>
                                                                
                                                            </div>
                                                        <div className ="content">
                                                            <p>Those preparing for personal, academic, or professional communication.
                                                            </p>
                                                           
                                                        </div>
                                                    </div>
                                                   
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className = "testimonialBox">
                                                    <div className="details">
                                                                <div className="imgBx">
                                                                <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                                </div>
                                                                
                                                            </div>
                                                        <div className ="content">
                                                            <p>Learners wanting to build confidence and fluency in conversation.
                                                            </p>
                                                           
                                                        </div>
                                                    </div>
                                                   
                                                </SwiperSlide>
                                        
                                 
                                            </Swiper> */}

                                            <video src={speakout} className="img-fluid d-block mx-auto" autoPlay loop muted playsInline></video>

                                        </section>


                                    </div>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="pe-3">
                                    <img src={advisorImage} className="img-fluid-adv" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* faq */}
                <section className="section-faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <video src={faqImg} className="img-fluid d-block mx-auto" autoPlay loop muted  >
                                </video>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    {/* <div className="col-md-10">
                                    <div className="spacing"></div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        At what time will my English class commence?
                                                    </button>
                                                </h4>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#ccordionExample">
                                                    <div className="accordion-body">

                                                        At Anglofone, we have flexibly structured our online English courses. You can learn spoken English
                                                        in your leisure time through WhatsApp. Your dedicated trainers will guide you by adapting to your
                                                        schedules.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Will I able to speak fluently within 2 months?
                                                    </button>
                                                </h4>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Learning pace is something that differs from person to person. Our course duration is 2 months. In
                                                        addition to that, your course is valid up to 9 months from which you can become more fluent and
                                                        learn beyond the course even after your course duration.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What is the procedure to enroll in the course?
                                                    </button>
                                                </h4>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can text the mentioned contact number on our website. Our course advisor will reach you. There
                                                        will be an online test to evaluate your level in English. After evaluating your test, our advisors
                                                        will suggest the suitable course to you, considering
                                                        your level of English.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        I'm working abroad. How can I enroll in the course?
                                                    </button>
                                                </h4>
                                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can access our English classes from any part of the world because our trainers are working
                                                        round the clock. We can definitely coordinate with your timings.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-why-choose-us section-testimonial-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {/* <h6>Testimonials</h6> */}
                                        <h2>What
                                            <span class="text-primary "> People&nbsp;Say</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ border: "none" }}>
                                        <h1 className="text-center"> </h1>
                                        <div class="tagembed-widget" style={{ width: "100%", height: "100%" }} data-widget-id="2139187" data-tags="false" view-url="https://widget.tagembed.com/2139187"></div><script src="https://widget.tagembed.com/embed.min.js" type="text/javascript"></script>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>

    )
}

export default SpeakOutCourse;