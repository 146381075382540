import NavBar from "./NavBar";
import CareerImage from "../Assets/Images/AboutUs/career-img.svg"
import "../CSS/Career.css"
import phoneIcon from "../Assets/Images/Tech/Icons/phone-icon.svg"
import mailIcon from "../Assets/Images/Tech/Icons/mail-icon.svg"
import Clients from "../Assets/Images/Tech/Clients/client.svg"
import Footer from "./Footer";
import banner from "../Assets/Images/Banner/about us banner.mp4"
import join from "../Assets/Images/AboutUs/Join us .jpg"
import vision from "../Assets/Images/AboutUs/Vision.jpg"
import mission from "../Assets/Images/AboutUs/Mission.jpg"
import banner1 from "../Assets/Images/Banner/3.jpg"
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";


const Career = () => {
    const [showArrow, setShowArrow] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // Track which card is active
    const [flipping, setFlipping] = useState(true);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);



    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const items = [
        { img: vision, title: "Our Vision" },
        { img: mission, title: "Our Mission" },
        { img: join, title: "Join Our Team" }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            if (flipping) {
                setActiveIndex((prevIndex) => (prevIndex + 1) % 3); // Change to the next card
            }
        }, 2000); // Flip every 4 seconds

        return () => clearInterval(interval);
    }, [flipping]);


    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        city: "",
        applyFor: "",
        language: "",
        resume: null
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setFormData({ ...formData, resume: reader.result.split(",")[1] }); // Extract Base64
            };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestBody = {
            Name: formData.name,
            PhoneNumber: formData.phoneNumber,
            City: formData.city,
            ApplyFor: formData.applyFor,
            Language: formData.language,
            Resume: formData.resume 
        };

        console.log("response body of APPLY",JSON.stringify(requestBody))

        try {
            const response = await fetch("https://rglvxgwa53.execute-api.ap-south-1.amazonaws.com/dev/SaveCarrer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
                
            });

            if (response.ok) {
                alert("Application submitted successfully!");
                
                setFormData({
                    name: "",
                    phoneNumber: "",
                    city: "",
                    applyFor: "",
                    language: "",
                    resume: null
                });
            } else {
                alert("Failed to submit application.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting application.");
        }
    };


    return (
        <div>
            <Helmet>
                <title>Careers at Anglofone | Join a Passionate Team Making Learning Easy</title>
                <meta name="description" content="Explore careers at Anglofone and join a team dedicated to transforming lives through quality English education worldwide." />
            </Helmet>
            <NavBar navIndex="Career" />

            <div className="col-md-12 relative-container" >
                <img src={banner1} className="banner-video" autoPlay loop muted playsInline style={{ position: "absolute", height: "250px" }}></img>



                {/* Overlay text container */}

                {/* <div className="banner-text">
                    <h1 style={{ marginTop: "15px", fontFamily: "Montserrat, sans-serif" }}>Career</h1>
                </div> */}
            </div>

            {/* 
            <section class="section-why-choose-us light-red-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="flip-container" >
                                <div class="flipper">
                                    <div class="front">
                                        <img src={vision} class="img-fluid" alt="Image 1" />
                                    </div>
                                    <div class="back">

                                        <h2 style={{ color: "red",fontWeight:"20px" }}>Our vision</h2>
                                        <p style={{ fontSize:"20px",fontWeight:"bold" }}>To create a world where everyone can communicate confidently in English </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="flip-container" >
                                <div class="flipper">
                                    <div class="front">
                                        <img src={mission} class="img-fluid" alt="Image 2" />
                                    </div>
                                    <div class="back">

                                        <h2 style={{ color: "red" }}>Our Mission</h2>
                                        <p style={{ fontSize:"20px",fontWeight:"bold" }}>To help everyone learn and use English in a better way and making it accessible for everyone.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="flip-container" >
                                <div class="flipper">
                                    <div class="front">
                                        <img src={join} class="img-fluid" alt="Image 3" />
                                    </div>
                                    <div class="back">

                                        <h2 style={{ color: "red" }}>Join Our Team</h2>

                                        <ul class="team-list">
                                            <li>
                                                Your creativity is welcomed and celebrated here.
                                            </li>
                                            <li>We transform bold ideas into groundbreaking realities</li>
                                            
                                            <li>
                                                We provide a motivating environment for young professionals
                                            </li >
                                            
                                            <li>
                                                Our growth relies on the skills of our valuable employees.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="section-why-choose-us light-red-bg">
                <div className="container">
                    <div className="row">
                        {items.map((item, index) => (
                            <div className="col-md-4" key={index}>
                                <div
                                    className={`flip-container ${activeIndex === index ? "flipped" : ""}`}
                                >
                                    <div className="flipper">
                                        <div className="front">
                                            <img src={item.img} className="img-fluid" alt={item.title} />
                                        </div>
                                        <div className="back">
                                            <h2 style={{ color: "red" }}>{item.title}</h2>
                                            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                                                {item.title === "Our Vision" && "To create a world where everyone can communicate confidently in English."}
                                                {item.title === "Our Mission" && "To help everyone learn and use English in a better way and making it accessible for everyone."}
                                                {item.title === "Join Our Team" && (
                                                    <ul className="team-list">
                                                        <li>Your creativity is welcomed and celebrated here.</li>
                                                        <li>We transform bold ideas into groundbreaking realities.</li>
                                                        <li>We provide a motivating environment for young professionals.</li>
                                                        <li>Our growth relies on the skills of our valuable employees.</li>
                                                    </ul>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section class="section-why-choose-us career-accordion">
                <div class="container ">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">

                                {/* <h6>Vacancy for you</h6> */}
                                <h2>Career <span class="text-primary">@ Anglofone</span>
                                </h2>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h4 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <span class="text-primary me-1">Language Trainer</span> <small class="ms-1 fw-light">
                                                        </small>
                                                    </button>
                                                </h4>
                                                <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                                                    data-bs-parent="#accordionExample">
                                                    <div class="accordion-body"><strong>Facilitates language learning through engaging lessons and personalized instruction.</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h4 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <span class="text-primary me-1">Course Advisor</span> <small class="ms-1 fw-light">
                                                        </small>
                                                    </button>
                                                </h4>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                                    data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong>Guides students in selecting appropriate courses to meet their educational goals.</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h4 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <span class="text-primary me-1">Media Editor</span> <small class="ms-1 fw-light"></small>
                                                    </button>
                                                </h4>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                                    data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong> Edits and enhances video and audio content to ensure high-quality production.</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h4 class="accordion-header" id="headingFour">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        <span class="text-primary me-1">Cinematographer</span> <small class="ms-1 fw-light"></small>
                                                    </button>
                                                </h4>
                                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                                    data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong>Captures compelling visuals for various media projects, ensuring artistic and technical excellence.</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h4 class="accordion-header" id="headingFive">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        <span class="text-primary me-1">Media Presenter</span> <small class="ms-1 fw-light"></small>
                                                    </button>
                                                </h4>
                                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                                    data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong> Delivers engaging content on-screen, connecting with audiences through effective communication.</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h4 class="accordion-header" id="headingSix">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        <span class="text-primary me-1"> HR & Administration</span> <small class="ms-1 fw-light"></small>
                                                    </button>
                                                </h4>
                                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                                                    data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <strong>Manages recruitment, employee relations, and administrative tasks to support organizational goals.</strong>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showArrow && (
                <button
                    className="back-to-top"
                    onClick={scrollToTop}
                    aria-label="Back to top"
                >
                    ↑
                </button>
            )}

            <section class="section-why-choose-us light-red-bg">
                <div class="container ">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="career-apply-box">
                            <form onSubmit={handleSubmit}>
    <div className="mb-3">
        <label className="form-label" style={{ color: "black" }}>Name</label>
        <input 
            type="text" 
            className="form-control" 
            placeholder="Your name"
            name="name"
            value={formData.name}
            onChange={handleChange}
        />
    </div>
    
    <div className="mb-3">
        <label className="form-label" style={{ color: "black" }}>Phone Number</label>
        <input 
            type="text" 
            className="form-control" 
            placeholder="Your phone number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
        />
    </div>

    <div className="mb-3">
        <label className="form-label" style={{ color: "black" }}>City</label>
        <input 
            type="text" 
            className="form-control" 
            placeholder="Your City"
            name="city"
            value={formData.city}
            onChange={handleChange}
        />
    </div>

    <div className="mb-3">
        <label className="form-label" style={{ color: "black" }}>Apply for</label>
        <select 
            className="form-select"
            name="applyFor"
            value={formData.applyFor}
            onChange={handleChange}
        >
            <option value="">Select role</option>
            <option value="Web Designer">Web Designer</option>
            <option value="Language Trainer">Language Trainer</option>
            <option value="Graphic Designer">Graphic Designer</option>
        </select>
    </div>

    <div className="mb-3">
        <label className="form-label" style={{ color: "black" }}>Language</label>
        <select 
            className="form-select"
            name="language"
            value={formData.language}
            onChange={handleChange}
        >
            <option value="">Select Language</option>
            <option value="Tamil">Tamil</option>
            <option value="Malayalam">Malayalam</option>
            <option value="Telugu">Telugu</option>
        </select>
    </div>

    <div className="mb-3">
        <label className="form-label" style={{ color: "black" }}>Upload Resume</label>
        <input 
            className="form-control" 
            type="file" 
            accept=".pdf" 
            onChange={handleFileChange} 
            required 
        />
    </div>

    <button className="btn btn-danger btn-theme d-block w-100 mt-4" type="submit">
        Apply
    </button>
</form>

                            </div>
                        </div>
                        <div class="col-md-6">
                            {/* <h6>Apply for job</h6> */}
                            <h2>Who <span class="text-primary">We Want ?</span>
                            </h2>
                            <p style={{ color: "black" }}>Passionate Individuals: If you’re driven, eager to tackle challenges, and ready to work hard, we want you!</p>

                            <div className="row">
                                {/* First Pair */}
                                <div className="col-12 col-md-6">
                                    <div className="contact-info d-flex">
                                        <img src={phoneIcon} alt="Phone" className="contact-icon" />
                                        <div className="ps-3">

                                            <label className="text-black language-label">Tamil:</label>

                                            <p className="inside-carrer">+91 9363285910</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="contact-info d-flex">
                                        <img src={mailIcon} alt="Mail" className="contact-icon-mail" />
                                        <div className="ps-3 mail-container">
                                            <label className="text-black">
                                                <span className="hide-on-mobile">for Mail:</span>
                                            </label>
                                            <p className="inside-carrer-mail">career@anglofone.co.in</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Second Pair */}
                                <div className="col-12 col-md-6">
                                    <div className="contact-info d-flex">
                                        <img src={phoneIcon} alt="Phone" className="contact-icon" />
                                        <div className="ps-3">
                                            <label className="text-black language-label">Malayalam:</label>
                                            <p className="inside-carrer">+91 6382059308</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="contact-info d-flex">
                                        <img src={mailIcon} alt="Mail" className="contact-icon-mail" />
                                        <div className="ps-3 mail-container">
                                            <label className="text-black">
                                                <span className="hide-on-mobile">for Mail:</span>
                                            </label>
                                            <p className="inside-carrer-mail">admin@anglofone.co.in</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Third Pair */}
                                <div className="col-12 col-md-6">
                                    <div className="contact-info d-flex">
                                        <img src={phoneIcon} alt="Phone" className="contact-icon" />
                                        <div className="ps-3">
                                            <label className="text-black language-label">Telugu:</label>
                                            <p className="inside-carrer">+91 7569830539</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="contact-info d-flex">
                                        <img src={mailIcon} alt="Mail" className="contact-icon-mail" />
                                        <div className="ps-3 mail-container">
                                            <label className="text-black">
                                                <span className="hide-on-mobile">for Mail:</span>
                                            </label>
                                            <p className="inside-carrer-mail-telugu">hrhyd@anglofone.in</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <Footer />
            </footer>
        </div>
    )
}


export default Career;